@import "/src/styles/mixin";

.reviews {
  padding-bottom: 100px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding-bottom: 60px;
  }

  &Content {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 60px;

    .rating_wrap {
      width: 100%;
      max-width: 345px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
      grid-column-gap: 20px;
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      align-items: flex-start;
      flex-direction: column-reverse;
      grid-row-gap: 32px;
    }
  }

  &List {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media only screen and (min-width: 320px) and (max-width: 1559.98px) {
      max-width: 700px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      gap: 32px;
    }
  }

  &All {
    @include contentButtonTransparent;
    padding: 14px 28px;
    text-align: center;
    align-self: flex-start;
  }
}

.empty {
  &Title {
    @include font16Bold;
    margin-bottom: 4px;
  }

  &Text {
    @include font14;
    margin-bottom: 20px;
  }
}
