@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 6px;
  width: auto;
  min-width: 250px;
  max-width: 350px;

  .text {
    @include font14;

    .link {
      @include font14;
      border-bottom: 1px dashed var(--black-color);
      margin-left: 2px;
    }
  }
}