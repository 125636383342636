@import "/src/styles/mixin";

.image_wrap {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  position: relative;
  gap: 8px;
  cursor: pointer;
  align-items: center;

  .custom_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-small);
    padding: 4px;
    background-color: var(--white-color);
    border: 1px solid var(--grey-lines-color);
    box-shadow: 0px 4px 2px rgba(0, 0, 0, .06);

    &:active {
      border: 1px solid var(--secondary-color);
    }
  }

  .button_resize_h {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .caption_wrap {
    position: relative;
    display: flex;
    height: auto;
    width: 50%;

    p {
      @include font14;
    }

    .caption {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, .15);
      margin: 0 4px;
      padding: 8px 4px;
      width: 100%;
      min-width: 100%;
      height: auto;
      min-height: 150px;
      border: 1px solid transparent;
      border-radius: var(--border-radius-normal);
      transition: border .25s ease;

      // resize: none;
      &:focus {
        outline: none;
        border: 1px solid var(--grey-lines-color);
      }
    }
  }

  .left {
    order: -1;
  }

  .right {
    order: 1;
  }

  @media screen and (max-width: 1242px) {
    flex-direction: column;
  }
}

.wrap {
  display: flex;
  position: relative;

  .img_wrap {
    position: relative;
    width: 100%;

    &_caption {
      width: 100%;
    }

    .mark {
      position: absolute;
      border-radius: 50%;
      background: var(--primary-color);
      color: var(--white-color);
      width: 16px;
      height: 16px;
      outline: solid 3px var(--primary-color);
      outline-offset: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      &_wrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 1
      }

      &_button {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .tooltip {
          width: 14px;
          height: 14px;
          display: flex;
          justify-content: center;
          align-content: center;
        }

        .icon {
          width: 14px;
          height: 14px;
          opacity: 0;
          transition: .25s ease;
        }

        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }
    }

    .new_product {
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--white-color);
      border: 1px solid var(--grey-lines-color);
      width: 220px;
      height: 320px;
      border-radius: var(--border-radius-normal);
      z-index: 10 !important;
      transition: all .25s;
      overflow: hidden;

      .wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 12px;
        justify-content: space-between;
        height: 100%;

        .close,
        .close_hide {
          position: absolute;
          top: 12px;
          right: 12px;

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: var(--white-color);
          padding: 4px 4px;
          border: 1px solid var(--grey-lines-color);
          border-radius: var(--border-radius-small);
          z-index: 3;
          transition: .25s ease-out;

          &:hover {
            border-color: var(--secondary-color);
          }
        }

        .close_hide {
          top: -1px;
          right: -1px;
        }

        .name {
          @include font14;
          letter-spacing: 0.01em;
          height: 48px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          color: var(--secondary-color);
          overflow: hidden;
        }

        .fake_image {
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
          object-fit: contain;
        }

        .form {
          display: flex;
          flex-direction: row;
          gap: 8px;
        }
      }
    }

    .hide {
      overflow: hidden;
      border-radius: var(--border-radius-small);
      animation: closed .5s step-end ease-in-out;
      height: 34px;
      width: 34px;
      transition: .25s;
      z-index: 1;

      @keyframes closed {
        0% {
          height: 34px;
        }

        100% {
          width: 34px;
        }
      }
    }
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 140px;
    min-width: 200px;
    z-index: 1;

    @media screen and (max-width: 767px) {
      max-height: 320px;
    }
  }

  .with_caption {
    width: 100% !important;
  }

  .resize {
    border: 3px solid var(--primary-color);
  }

  .normal {
    border: 3px solid transparent;
  }

  .actions {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 8px;
    padding: 4px;
    background-color: #ffffff50;
    border: 1px solid var(--grey-lines-color);
    box-shadow: 0px 4px 2px rgba(0, 0, 0, .06);
    border-radius: var(--border-radius-small);
    backdrop-filter: blur(20px);

    .button {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 4px 4px;
      border-radius: 4px;
      z-index: 2;
      
      .close_icon {
        width: 24px;
        height: 24px;

        path {
          fill: var(--secondary-color);
          transition: .25s;
        }

        rect {
          fill: var(--secondary-color);
          transition: .25s;
        }
      }

      &:hover {
        .close_icon {
          path {
            fill: var(--primary-color);
          }

          rect {
            fill: var(--primary-color);
          }
        }
      }
    }
  }

}

.with_caption {
  width: 48% !important;
}

.resize_icon {
  width: 20px;
  height: 20px;
}