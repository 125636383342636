@import "/src/styles/mixin";

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: var(--blue-background-color);
  padding: 4px 12px;
  border-radius: 100px;
  &Choosed {
    background-color: var(--grey-lines-color);
    order: -1;
  }
  &:nth-child(n + 6) {
    display: none;
  }
}

.text {
  @include font14;
  white-space: nowrap;
}

.close {
  display: flex;
  width: 18px;
}
