@import "src/styles/mixin";

.comment {
  @include font14;

  &Wrap {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &Title {
    @include font16Bold;
    margin-bottom: 4px;
  }
}