@import "/src/styles/mixin";

.tabs_wrapper {
  display: flex;
  flex-direction: column;

  .tabs_header {
    display: flex;
    column-gap: 32px;
    flex-wrap: nowrap;
    overflow-y: hidden;

    @media only screen and (max-width: 1023px) {
      column-gap: 24px;
    }

    @media only screen and (max-width: 767px) {
      column-gap: 16px;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      display: none;
    }
  }

  .tabs_container {
    .tab_description {
      display: flex;
    }

    .tab_stars {
      display: flex;
    }

    .tabs_reviews {
      display: flex;
    }

    .tab_analog {
      display: flex;
    }

    .tabs_questions {
      display: flex;
    }

    .tabs_delivery {
      display: flex;
    }

    .hidden {
      display: none;
    }

    .active {
      display: block;
    }
  }
}

.komplektatsiya {
  @include font14;
  margin: 10px 0;
}
