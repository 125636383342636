@import "/src/styles/mixin";

.article {
  max-width: 950px;
  margin: 0 auto;
  margin-bottom: 60px;

  .title {
    @include font30;
    margin-bottom: 32px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    gap: 16px;

    .tag {
      padding: 8px 16px;
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .preview_text {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;

    h4 {
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 32px;
      font-weight: 800;
    }

    h5 {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 32px;
      font-weight: 800;
    }

    ul {
      list-style-type: disc;
      padding-left: 12px;
      margin-bottom: 32px;
    }

    li {
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
    }

    p {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
    }

    a {
      position: relative;
      cursor: pointer;
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed var(--grey-color);
        transition: color 0.2s ease;
      }

      &:hover {
        &::after {
          color: var(--primary-color);
        }
      }
    }
  }

  .interactive {
    margin-bottom: 32px;
    position: relative;

    &_background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: var(--border-radius-medium);
    }

    &-label {
      position: absolute;
      width: 20px;
      height: 20px;
      top: var(--top);
      left: var(--left);
      bottom: var(--bottom);
      right: var(--right);
      border-radius: 100%;
      border: 4px solid var(--primary-color);
      box-shadow: 0 0 0 rgba(255, 221, 45, 0.5);
      animation: pulse 3s infinite;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        background-color: var(--primary-color);
        transform: translate(-50%, -50%);
        border-radius: 100%;
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 221, 45, 0.5);
        }

        40% {
          box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        }

        100% {
          box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        }
      }
    }

    &-text {
      @include font14;
    }

    .img {
      display: block;
      height: 100%;
      border-radius: var(--border-radius-medium);
    }

    .card-item {
      max-width: 288px;
      width: 100%;
      position: absolute;
      top: var(--top);
      left: var(--left);
      bottom: var(--bottom);
      right: var(--right);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease;

      &__link {
        @include font14;
        margin-bottom: 10px;
      }

      &__button {
        display: inline-block;
      }

      &.is-active {
        visibility: visible;
        opacity: 1;
        z-index: 1;
      }
    }
  }

  .description {
    h4 {
      font-size: 18px;
      line-height: 32px;
      font-weight: 800;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    h5 {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 32px;
      font-weight: 800;
    }

    ul {
      list-style-type: disc;
      padding-left: 12px;
      margin-bottom: 32px;
    }

    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    a {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 3px;
      transition: color 0.2s ease;
      font-size: 16px;
      line-height: 32px;
      font-weight: 800;

      &:hover {
        color: var(--primary-color);
      }
    }

    img {
      border-radius: var(--border-radius-medium);
    }

    .img-wrap {
      display: flex;
      gap: 40px;
      margin-bottom: 32px;

      img {
        width: 360px;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1560px) {
  .article {
    &__title {
      margin-bottom: 24px;
    }

    &__tags {
      &-list {
        margin-bottom: 24px;
      }
    }

    &__preview-text {
      margin-bottom: 24px;
    }

    .interactive {
      margin-bottom: 24px;
    }

    &__description {
      h4,
      p,
      a,
      ul {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .article {
    &__description {
      .img-wrap {
        flex-direction: column;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .article {
    &__title {
      margin-bottom: 24px;
    }

    &__tags {
      &-list {
        margin-bottom: 24px;
      }
    }

    &__preview-text {
      margin-bottom: 24px;
    }

    .interactive {
      margin-bottom: 24px;

      .card-item {
        max-width: 150px;
      }
    }

    &__description {
      .img-wrap {
        margin-bottom: 24px;
        gap: 24px;
      }
    }
  }
}
