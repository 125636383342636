.block_button {
  transition: all .25s;
  display: flex;
  align-items: center;
  padding: 4px 4px;
  border: 1px solid var(--grey-lines-color);
  border-radius: 6px;
  transition: .25s ease;

  svg {
    width: 24px;
    height: 24px;
    transition: all .25s;
  }
}

.block_button:hover {
  border-color: var(--grey-color);
}

.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);

  svg {
    fill: var(--white-color);
    transition: all .25s;

    path {
      fill: var(--white-color);
    }
  }
}

.not_active {
  svg {
    fill: var(--secondary-color);
    transition: all .25s;

    path {
      fill: var(--secondary-color);
    }
  }
}