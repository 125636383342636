@import "/src/styles/mixin";

.wrap {
  padding: 12px 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }
}

.title {
  @include font16Bold;
  &Wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.all {
  display: flex;
  align-items: center;
  gap: 8px;
  @include font16;
  color: var(--grey-color);
}

.icon {
  transform: rotate(180deg);
}

.filtersWrap {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
