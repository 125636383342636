@import "/src/styles/mixin";

.title {
  @include font30;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &Wrap {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    margin-bottom: 40px;

    @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
      margin-bottom: 30px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      margin-bottom: 20px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      margin-bottom: 16px;
      gap: 8px;
    }
  }
}

.count {
  @include font12;
  font-weight: 500;
}

.wrap {
  display: flex;
  margin-bottom: 60px;
  gap: 50px;

  // justify-content: space-between;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    display: block;
    margin-bottom: 44px;
  }

  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    margin-bottom: 0;
  }
}

.cards {
  &Wrap {
    flex: 1 1 auto;
    width: 50%;

    @media only screen and (min-width: 320px) and (max-width: 1365px) {
      width: 100%;
    }
  }
}

.banner {
  height: auto;

  &Link {
    display: flex;
  }

  &Swiper {
    padding-bottom: 24px;
    margin-bottom: 44px;

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      margin-bottom: 24px;
    }
  }
}