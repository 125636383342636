@import "/src/styles/mixin";

.storesAll {
  @include font14;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-darker-color);
  &Icon {
    transform: rotate(180deg);
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nested {
  &Title {
    @include font18;
  }
  &Wrap {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: var(--border-radius-normal);
  }
  &Text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.choosedCity {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &Text {
    @include font14;
    color: var(--grey-darker-color);
  }
  &Geo {
    margin-right: 0;
  }
}

.delivery {
  &Title {
    @include font20;
    margin-bottom: 8px;
  }
  &Price {
    @include font12;
    padding: 0 4px;
    background-color: var(--primary-color);
  }
  &Wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &Text {
    @include font12;
    font-weight: 500;
  }
  &Company {
    &Wrap {
      display: flex;
      gap: 8px;
    }
    &Img {
      max-width: 75px;
      height: 37px;
      object-fit: contain;
      &Wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
      }
    }
    &Text {
      @include font14;
    }
  }
}
