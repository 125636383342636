.close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 7px;

  .icon {
    width: 10px;
    height: 10px;
  }
}
