@import "/src/styles/mixin";


.authorWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;

  .author {
    @include font18;
  }

  .date {
    @include font14;
    font-weight: 500;
    color: var(--grey-color);
  }
}

.image_list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .image_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 126px;
    overflow: hidden;
    border-radius: var(--border-radius-small);

    .image {
      width: 126px;
      // height: auto;
      cursor: zoom-in;
      object-fit: cover;
      border-radius: var(--border-radius-small)
    }
  }
}

.comment {
  @include font14;

  &Wrap {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &Title {
    @include font16Bold;
    margin-bottom: 4px;
  }
}

.answerWrap {
  padding-left: 70px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 40px;
    width: 1px;
    height: 100%;
    background-color: var(--primary-color);
  }
}

.stars {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
}

.pictures {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;

  .img {
    width: 122px;
    height: auto;
    object-fit: contain;
  }
}