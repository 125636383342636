@import "src/styles/mixin";

.catalog_wrap {
  position: absolute;
  top: calc(100% + 10px);
  left: 0px;
  bottom: calc(-100svh + 130px);
  padding: 20px 10px 20px 20px;
  display: flex;
  background-color: var(--white-color);
  width: 100%;
  max-width: 1440px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
  z-index: 3;
  flex-direction: column;
  justify-content: center;

  .catalog_header {
    position: absolute;
    right: 20px;
    top: 20px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 802px;
    z-index: 2;

    .close {
      display: block;
    }

    .back_button {
      display: flex;
      align-items: center;
      gap: 4px;
      flex-wrap: nowrap;
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.01em;

      .icon {
        width: 14px;
        transform: rotate(90deg);
      }
    }

    @media screen and (max-width: 1024px) {
      position: static;
      width: 100%;
      right: unset;
      top: unset;
      margin: 0 auto;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
  }

  @media screen and (max-width: 460px) {
    padding: 16px;
  }
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
