// .tooltip_container {
//   position: relative;
//   display: inline-block;

//   .tooltip_box,
//   .tooltip_box_active {
//     position: absolute;
//     bottom: 100%;
//     left: 50%;
//     transform: translateX(-50%);
//     background-color: black;
//     color: white;
//     text-align: center;
//     padding: 5px;
//     border-radius: 5px;
//     white-space: nowrap;
//     opacity: 0;
//     transition: opacity 0.3s;
//     z-index: 102;

//     &::after {
//       content: '';
//       position: absolute;
//       top: 100%;
//       left: 50%;
//       transform: translateX(-50%);
//       border-width: 5px;
//       border-style: solid;
//       border-color: black transparent transparent transparent;
//     }
//   }

//   .tooltip_box_active {
//     opacity: 1;
//   }
// }


.tooltip_container {
  position: relative;
  display: inline-block;

  .tooltip_box,
  .tooltip_box_active {
    position: absolute;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
    max-width: 200px;
    height: auto;
    z-index: 1000;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  .tooltip_box_active {
    opacity: 1;
  }

  .top {
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: black transparent transparent transparent;
    }
  }

  .bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: transparent transparent black transparent;
    }
  }

  .left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);

    &::after {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent black;
    }
  }

  .right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);

    &::after {
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent black transparent transparent;
    }
  }
}