@import "src/styles/mixin";

.wrap {
  position: relative;
  // overflow: hidden;
  background-color: var(--white-color);
  width: 100%;

  .preview_picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 12px;
    border-radius: var(--border-radius-normal);
  }

  .editable {
    width: 100%;
    outline: none;
    overflow: hidden;

    blockquote {
      margin: 12px 4px;
    }
  }

  .full_size {
    max-height: 100%;
    overflow: auto;
  }

  .show {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 95%);
    transition: all .25s;

    .show_button {
      @include contentButton;
      padding: 8px 16px;
      line-height: 24px;
    }
  }

  .hide {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .show_button {
      @include contentButton;
      padding: 8px 16px;
    }
  }
}