@import "/src/styles/mixin";

.qrContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrWrapper {
  margin-bottom: 12px;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  @include font14;
  margin-bottom: 12px;
  text-align: center;
}

.text {
  @include font12;
  font-weight: 500;
  text-align: center;
  line-height: normal;
  font-style: italic;
}

.timer {
  @include font14;
  min-width: 260px;
  text-align: center;
}

.qrVisible {
  filter: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.qrBlurred {
  filter: blur(5px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.refreshButton {
  @include font12;
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white-color);
}
