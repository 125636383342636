@import "/src/styles/mixin";

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: var(--grey-lines-color);
  padding: 4px 12px;
  border-radius: 100px;
}

.text {
  @include font14;
  white-space: nowrap;
}

.close {
  display: flex;
  width: 18px;
}
