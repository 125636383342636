@import "/src/styles/mixin";

.properties {
  width: 100%;
  columns: 2;
  column-gap: 60px;
  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    column-gap: 40px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    columns: 1;
  }
}

.dot {
  max-width: 445px;
}
