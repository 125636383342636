.wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.close,
.more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 100%;
  background-color: var(--grey-lines-color);
}

.icon {
  display: flex;
  width: 18px;
}
