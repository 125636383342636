@import "src/styles/mixin";

.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-y: scroll;

  @include customScroll();
}
