@import "/src/styles/mixin";

.switch {
  &Button {
    &Wrap {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px;
      background-color: var(--blue-background-color);
      border-radius: var(--border-radius-small);
    }
  }

  &Button,
  &ButtonActive {
    @include font14;
    padding: 9px 0;
    width: 100%;
    max-width: 145px;
    color: var(--grey-lines-color);
    border-radius: var(--border-radius-small);
    transition: background 0.2s ease;
  }

  &ButtonActive {
    background-color: var(--white-color);
    color: var(--black-color);
  }
}

.title {
  @include font16Bold;
  margin-bottom: 12px;
  text-align: left;
}

.infoText {
  @include font14;
  margin-bottom: 12px;
  text-align: center;
}

.input {
  &Wrap {
    margin-bottom: 24px;
  }
}

.button {
  @include font14;
  padding: 14px 26px;
  width: 100%;
  margin-bottom: 24px;
  font-weight: 700;
  background-color: var(--primary-color);

  &Action {
    @include font14;
    display: block;
    padding: 14px 26px;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
    background-color: var(--blue-background-color);
    border-color: var(--blue-background-color);
  }
}

.or {
  @include font14;
  position: relative;
  margin-bottom: 30px;
  color: var(--grey-color);
  text-align: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: var(--grey-lines-color);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.link {
  display: flex;
}

.buttonLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
  width: 32px;
  height: 32px;
  background-color: var(--primary-color);
}

.another {
  &Text {
    @include font14;
    margin-bottom: 12px;
  }

  &Wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &List {
    display: flex;
    margin-bottom: 16px;
    gap: 24px;
    align-items: center;
  }
  &Button {
    margin-bottom: 8px;
    border-bottom: 1px solid var(--black-color);
  }
}

.auth_variants {
  display: flex;
  flex-direction: column;
  gap: 27px;

  .variants_title {
    position: relative;
    @include font14;
    font-weight: 700;
    text-align: center;
    color: var(--grey-third);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--grey-third);
      margin-top: 4px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--grey-third);
      margin-top: 4px;
    }
  }

  .variants_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .variants {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      max-width: 200px;
      justify-content: space-between;
      gap: 6px;
      margin-top: 12px;

      button {
        cursor: not-allowed;
      }
    }
  }
}
