@import "/src/styles/mixin";

.wrap {
  padding: 24px 0 0;
  border-top: 1px solid var(--grey-lines-color);
}

.title {
  @include font16Bold;
  margin-bottom: 12px;
}
