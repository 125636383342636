@import "/src/styles/mixin";

.title {
  @include font18;
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius-normal);
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding: 24px 12px;
  }
}
