@import "/src/styles/mixin";

.wrap {
  width: calc(100vw - 80px);
  max-width: 703px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
}

.item {
  display: flex;
  gap: 65px;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &Info {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &Name {
    @include font14;
    font-weight: 500 !important;
  }
}

.title {
  @include font24;
  line-height: 24px !important;
}

.image {
  object-fit: contain;
  &__container {
    width: 100px;
    height: 100px;
  }
}

.rate {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: 0.25s;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 20px;
  }

  &Text {
    @include font18;
    font-weight: 700 !important;
  }
}

.clickedRate {
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
  height: 32px;

  .rateCheck {
    font-size: 32px;
    line-height: 28px;
    transition: 0.15s;
    background-color: transparent;
    border: none;
  }

  .active {
    color: var(--primary-color);
  }

  .noActive {
    color: var(--grey-lines-color);
  }
}

.form {
  max-width: 703px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &Hint {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      margin-bottom: 16px;
    }

    &Required {
      @include font14;
      color: #1d1d1d;
      font-weight: 250;
    }

    &WithHover {
      @include font14;
      color: #3caa3c;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      position: relative;

      &:hover {
        .formHintWithHoverWrap {
          opacity: 1;

          @media only screen and (min-width: 320px) and (max-width: 767.98px) {
            opacity: 0;
          }
        }
      }

      &Wrap {
        opacity: 0;
        position: absolute;
        top: 24px;
        left: -40px;
        height: 0px;
        transition: all 500ms;
        z-index: 3;

        @media screen and (max-width: 1365px) {
          left: -170px;
        }

        @media screen and (max-width: 1023px) {
          left: -285px;
        }
      }

      &TextContainer {
        padding: 20px;
        width: 403px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px #00000040;
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: black;
      }
    }

    &List {
      &Title {
        @include font14;
        line-height: 14px !important;
        font-weight: 600;
      }

      &Item {
        @include font14;
        line-height: 32px !important;
        font-weight: 500;
        display: flex;
        align-items: center;

        &::before {
          content: ".";
          height: 4px;
          margin-right: 8px;
          margin-bottom: 14px;
          font-size: 30px;
          line-height: 4px;
          color: #c9ced6;
        }
      }
    }

    &Triangle {
      margin-left: 25px;
      width: 60px;
      height: 20px;
      overflow: visible;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        background: var(--white-color);
        transform: rotate(45deg);
        top: 10px;
        left: 7px;
        box-shadow: 0px 0px 10px 0px #00000040;
      }

      @media screen and (max-width: 1365px) {
        margin-left: 155px;
      }

      @media screen and (max-width: 1023px) {
        margin-left: 270px;
      }

      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        display: none;
      }
    }
  }

  &Label {
    @include font16;
    font-weight: 400 !important;
    line-height: 24px;
  }

  &Textarea {
    @include font14;
    width: 100%;
    padding: 16px 16px 36px 16px;
    border: 1px solid #c9ced6;
    border-radius: var(--border-radius-small);
    resize: none;
    margin: 4px 0 20px 0;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      margin-top: 8px;
      border-radius: 5px;
    }

    &Container {
      position: relative;
    }

    &::placeholder {
      @include font16;
      line-height: 16px !important;
      font-weight: 400 !important;
      color: #9ea0a3;
    }

    &:focus {
      outline: none;
      border-color: var(--secondary-color);
    }

    &.is-not-valid {
      border-color: var(--red-color);
      animation: 0.1s tremor ease-out 1;
    }

    &:hover {
      border-color: var(--secondary-color);
    }
  }
}

.button {
  padding: 14px 26px;

  &Text {
    @include font14;
  }
}

.rightsInfo {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 250;
  line-height: 24px;
  display: flex;
  flex-direction: column;

  &Link {
    text-decoration: underline;
    display: inline;
  }
}
