@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  max-width: 950px;
  margin: 0 auto;
  margin-bottom: 60px;

  .haed {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grey-lines-color);
    margin-bottom: 24px;

    .title {
      @include font30;
      margin-bottom: 24px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px;
      gap: 16px;

      .tag {
        padding: 8px 16px;
        border: 1px solid var(--grey-lines-color);
        border-radius: var(--border-radius-small);
        font-size: 16px;
        line-height: 32px;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    .description {
      @include font18;
      margin-bottom: 32px;
    }

    .preview_pic {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 458px;
    }
  }
}