@import "/src/styles/mixin";

.categories {
  margin-bottom: 190px;
  padding: 50px 0;
  overflow: hidden;
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    margin-bottom: 90px;
  }
  @media only screen and (min-width: 500px) and (max-width: 1023.98px) {
    padding: 34px 0;
    margin-bottom: 50px;
  }
  &List {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    @media only screen and (min-width: 500px) and (max-width: 1023.98px) {
      gap: 40px 32px;
    }
    @media only screen and (min-width: 320px) and (max-width: 499.98px) {
      gap: 40px 0;
    }
  }
}
