@import "/src/styles/mixin";

.card {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 12px 0;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid var(--grey-lines-color);

  .card_head {
    display: flex;
    margin-bottom: 12px;

    .card_photo__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 85px;
      max-height: 85px;
      margin-right: 8px;
      align-self: flex-start;
      border-radius: var(--border-radius-small);
      background-color: var(--blue-background-color);
      mix-blend-mode: multiply;
      object-fit: contain;

      img {
        width: 85px;
        height: 85px;
        aspect-ratio: 1/1;
        mix-blend-mode: multiply;
        object-fit: contain;
      }

    }

    .card_name__tag {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      column-gap: 12px;

      .card_name__wrap {
        @include font14;
        max-width: 446px;

        @media screen and (max-width: 535px) {
          order: 2;
        }
      }

      .card_tag__wrap {
        position: relative;
        height: fit-content;
        @include dashedBottomLine;
        display: flex;
        width: auto;
        justify-self: flex-end;
        flex-direction: column;
        align-items: flex-end;
        white-space: nowrap;
        gap: 6px;

        .license_button {
          @include font14;
          color: var(--grey);
        }

        @media screen and (max-width: 535px) {
          order: 1;
        }

        &::before {
          border-color: var(--grey);
        }
      }

      @media screen and (max-width: 580px) {
        justify-content: flex-start;
        row-gap: 6px;
        align-items: baseline;
        flex-direction: column;
      }

    }
  }

  .card_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .card_point__wrap {

      .card_tag {
        color: var(--grey-lines-color) !important;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}