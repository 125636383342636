@import "/src/styles/mixin";

.link {
  @include font14;
  display: flex;
  align-items: center;
  color: var(--grey-darker-color);
  white-space: nowrap;
  transition: color 0.2s ease;
  &:hover {
    color: var(--black-color);
  }
  &::after {
    content: "";
    display: inline-block;
    margin-left: 8px;
    width: 6px;
    height: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--secondary-color) var(--secondary-color) transparent
      transparent;
    transform: rotate(45deg);
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    @include font12;
    font-weight: 500;
  }
}

.text {
  @include font14;
  white-space: nowrap;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    @include font12;
    font-weight: 500;
  }
}
