@import "/src/styles/mixin";

.hamburger {
  @include font14Bold;
  padding: 12px 16px;
  margin-right: 16px;
  display: flex;
  gap: 6px;
  align-items: center;

  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    margin-right: 12px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 0;
    gap: 0;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}

.icon {
  width: 100%;
  max-width: 16px;

  path {
    transition: all .2s ease;
  }

  path:nth-child(2) {
    transition: all .1s ease;
  }
}

.active {
  path:nth-child(1) {
    transform: rotate(45deg) translate(5px, -6px);
    left: 4px;
  }

  path:nth-child(2) {
    opacity: 0;
  }

  path:nth-child(3) {
    transform: rotate(-45deg) translate(-12px, -1px);
  }
}