.input {
  &Wrap {
    margin-bottom: 16px;
  }
}

.button {
  padding: 16px;
  width: 100%;
  &Wrap {
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: unset;
    background-color: var(--white-color);
  }
}

.wrap {
  position: relative;
  padding-bottom: 60px;
}
