.toolbar {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border: 1px solid var(--grey-lines-color);
  border-radius: var(--border-radius-small);
  background-color: #FFFFFF50;
  backdrop-filter: blur(20px);

  .icon {
    rect {
      fill: var(--secondary-color);
    }
  }
}